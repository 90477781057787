import React, { MouseEvent } from "react";
import { useImage } from "../utils";
import Binding from "./Binding";
import "./GoogleSSO.css";

interface GoogleSSOProps {
  account: Account;
  bindUrl?: string | null;
  bind: () => Promise<void>;
  unbind?: () => Promise<void>;
}

interface Account {
  email?: string;
  googleEmail?: string;
  hasGoogle?: boolean;
  hasMS?: boolean;
  id?: string;
  isSSO?: boolean;
  ssoFrom?: string;
}

const GoogleSSO: React.FC<GoogleSSOProps> = ({
  account,
  bindUrl,
  bind,
  unbind,
}) => {
  const googleIcon = useImage(
    `${process.env.REACT_APP_PUBLIC_URL}/googleSSO.svg`
  );

  const icon = (
    <object
      type="image/svg+xml"
      data={googleIcon}
      className="x-icon"
      aria-label="Google"
    />
  );
  const { email, googleEmail, hasGoogle, hasMS, id, isSSO, ssoFrom } = account;

  const handleBind = async (event: MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    await bind();
  };

  const handleUnbind = async (event: MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (unbind) await unbind();
  };

  return (
    <div className="google">
      {hasGoogle ? (
        <Binding
          icon={icon}
          onClick={handleUnbind}
          canUnbind={!(ssoFrom === "Google" && isSSO)}
        >
          {googleEmail}
        </Binding>
      ) : bindUrl ? (
        <a id="google-signin" href={bindUrl} target="_self">
          {icon}
          使用 Google 帳戶登入
        </a>
      ) : (
        <div id="google-signin" onClick={handleBind}>
          {icon}
          使用 Google 帳戶登入綁定
        </div>
      )}
    </div>
  );
};

export default GoogleSSO;
