import React, { useState, useEffect, useContext, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useLocalStorage, usePrevious } from "@mantine/hooks";
import { AuthContext } from "../Auth";
import Header from "../components/Header";
import Footer from "../components/Footer";
import SettingForm from "../components/SettingForm";
import {
  appleBindAPI,
  getMeAPI,
  updateMeAPI,
  googleBindAPI,
  microsoftBindAPI,
  unbindAPI,
  subscribePremiumAPI,
  subscribeStandardAPI,
  listSubscriptionsAPI,
  CCPro,
  CCStandard,
} from "../Api";
import GoogleSSO from "../components/GoogleSSO";
import AppleSSO from "../components/AppleSSO";
import MicrosoftSSO from "../components/MicrosoftSSO";
import { Button, Flex, Box, Title } from "@mantine/core";

enum BundleId {
  CCPro = "CCPro",
  CCStandard = "CCStandard",
}

export const Settings = () => {
  const navigate = useNavigate();
  const [isAuth, setAuth, removeAuth] = useContext(AuthContext);
  const previousAuth = usePrevious(isAuth);
  const [settings, setSettings] = useState({});
  const [bundleId, setBundleId] = useState<BundleId | string>("");
  const [saves, setSaves] = useLocalStorage({
    key: "saves",
    defaultValue: {},
    getInitialValueInEffect: false,
    serialize: (value: any) => {
      /* return value serialized to string */
      console.log("serialize", value);
      return JSON.stringify(value);
    },
    deserialize: (sessionStorageValue: string) => {
      /* parse sessionStorage string value and return value */
      console.log("deserialize", sessionStorageValue);
      return JSON.parse(sessionStorageValue);
    },
  });
  const [licenses, setLicenses, removeLicenses] = useLocalStorage({
    key: "licenses",
    defaultValue: [],
    getInitialValueInEffect: true,
    serialize: (value: any) => {
      /* return value serialized to string */
      console.log("licenses serialize", value);
      return JSON.stringify(value);
    },
    deserialize: (sessionStorageValue: string) => {
      /* parse sessionStorage string value and return value */
      console.log("licenses deserialize", sessionStorageValue);
      return JSON.parse(sessionStorageValue);
    },
  });

  const fetchSettings = useCallback(async () => {
    const response = await getMeAPI(isAuth);

    if (response.status && response.data) {
      const { data: me } = response;
      setSettings(me);
    } else {
      console.log("Error retrieving settings:");
    }
  }, [isAuth, setSettings]);

  const handleClicked = async () => {
    await Promise.all([removeAuth(), removeLicenses()]);
    navigate("/displayshare");
  };

  useEffect(() => {
    if (isAuth) fetchSettings();
  }, [isAuth, fetchSettings]);

  useEffect(() => {
    const paramsMatch = window.location.href.match(/\?.+/);
    if (paramsMatch) {
      const params = new URLSearchParams(paramsMatch[0]);
      const isAuth = params.get("token");
      if (isAuth) {
        setAuth(isAuth);
      }
    }
    if (previousAuth !== undefined && !isAuth) navigate("/displayshare");
  }, [isAuth, setAuth, previousAuth, navigate]);

  const googleBind = async () => {
    const { status, url } = await googleBindAPI(isAuth);
    if (status) window.location = url;
  };
  const appleBind = async () => {
    const formData = {};
    const response = await appleBindAPI(formData);
    const { status, url } = (response as { status: any; url: any }) || {};
    if (status) {
      window.location = url;
    } else {
      console.log("Invalid response or missing status");
    }
  };

  const microsoftBind = async (isAuth: boolean) => {
    const formData = {};
    const { status, url } = await microsoftBindAPI(isAuth, formData);
    if (status) {
      window.location = url;
    }
  };

  const unbind = (unbindParam: any) => async () => {
    const { email, id } = settings as any;

    await unbindAPI(isAuth, JSON.stringify({ unbindParam, email, id })).then(
      (data) => {
        if (data.status) setSettings(data.data);
      }
    );
  };
  const subscribePremium = async () => {
    if (bundleId === BundleId.CCPro) return;
    const subscription = await subscribePremiumAPI(isAuth);
    if (subscription.status) {
      const { data } = subscription;
      if (Array.isArray(data)) {
        if (data.length > 0) {
          const shiftedData = data.shift();
          setBundleId(shiftedData);
        }
      }
    }
  };

  const subscribeStandard = async () => {
    if (bundleId === BundleId.CCStandard) return;
    const subscription = await subscribeStandardAPI(isAuth);
    if (subscription.status) {
      const { data } = subscription;
      if (data.length > 0) {
        const shiftedData = data.shit();
        setBundleId(shiftedData);
      }
    }
  };

  const listSubscriptions = useCallback(async () => {
    const response = await listSubscriptionsAPI(isAuth);
    const { status, data } = response;

    if (status && data.length > 0) setBundleId(data.shift());
  }, [isAuth]);

  const handleSubmit = async (form: any, data: any) => {
    console.log("handleSubmit", data);
    const response = await updateMeAPI(isAuth, {
      email: data.email,
    });
    if (response.status) {
      setSaves(data);
    } else {
      const { message } = response;
      form.setErrors({ email: message });
    }
  };

  useEffect(() => {
    if (isAuth) listSubscriptions();
  }, [isAuth, listSubscriptions]);

  // const { email, hasApple, hasGoogle, hasMS, id } = settings;
  return (
    <div className="settings-container">
      <Header>
        <button className="login-button" onClick={handleClicked}>
          <img
            src={`${process.env.REACT_APP_PUBLIC_URL}/icon_exit_2.png`}
            alt="Back"
          />
        </button>
      </Header>
      <div className="content">
        <SettingForm settings={settings as any} onSubmit={handleSubmit}>
          <GoogleSSO
            account={settings}
            bind={googleBind}
            unbind={unbind("google")}
          />
          <AppleSSO
            account={settings}
            bind={appleBind}
            unbind={unbind("apple")}
          />
          <MicrosoftSSO
            account={settings}
            bind={microsoftBind}
            unbind={unbind("microsoft")}
          />
        </SettingForm>
        <Box maw={600} mx="auto" className="subscribe">
          <Title order={3} weight={500}>
            Subscription
          </Title>
          <Flex m={10} justify="space-between" direction="row" wrap="wrap">
            <Button
              className="premium"
              variant={bundleId === CCPro ? "subtle" : "outline"}
              size="lg"
              onClick={subscribePremium}
              disabled={bundleId ? true : false}
            >
              Premium
            </Button>
            <Button
              className="standard"
              variant={bundleId === CCStandard ? "subtle" : "outline"}
              size="lg"
              onClick={subscribeStandard}
              disabled={bundleId ? true : false}
            >
              Standard
            </Button>
          </Flex>
        </Box>
      </div>
      <Footer />
    </div>
  );
};
