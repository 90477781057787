export const platformList = [
  {
    platform: "windows",
    img: `${process.env.REACT_APP_PUBLIC_URL}/windows.svg`,
    subject: "Display Share for Windows (Installer)",
    description: "Windows 8/ 8.1/ 10/ 11",
    download: `${process.env.REACT_APP_PUBLIC_URL}/download/Display Share.exe`,
  },
  {
    platform: "windows",
    img: `${process.env.REACT_APP_PUBLIC_URL}/windows.svg`,
    subject: "Display Share for Windows (Portable)",
    description: "Windows 8/ 8.1/ 10/ 11",
    download: `${process.env.REACT_APP_PUBLIC_URL}/download/Display Share.zip`,
  },
  {
    platform: "mac",
    img: `${process.env.REACT_APP_PUBLIC_URL}/apple.svg`,
    subject: "Display Share for Mac",
    description: "MacOS 10.12 and above",
    download: `${process.env.REACT_APP_PUBLIC_URL}/download/Display Share.dmg`,
  },
  {
    platform: "android",
    img: `${process.env.REACT_APP_PUBLIC_URL}/android.svg`,
    subject: "Display Share for Android",
    description: "Android 9.0 and above",
    download:
      "https://play.google.com/store/apps/details?id=com.optoma.DisplayShare",
  },
  {
    platform: "iphone",
    img: `${process.env.REACT_APP_PUBLIC_URL}/apple.svg`,
    subject: "Display Share for iPhone",
    description: "iOS 14.0 and above",
    download: "https://apps.apple.com/us/app/display-share/id6463402720",
  },
];
