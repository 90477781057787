import React from "react";
import { useImage } from "../utils";
import Binding from "./Binding";
import "./MicrosoftSSO.css";

const MicrosoftSSO = ({ account, bindUrl, bind, unbind }: any) => {
  const microsoftIcon = useImage(
    `${process.env.REACT_APP_PUBLIC_URL}/microsoft.svg`
  );

  const handleBind = async (event: any) => {
    event.preventDefault();
    bind();
  };

  const handleUnbind = async (event: any) => {
    event.preventDefault();
    unbind();
  };

  const { email, msEmail, hasGoogle, hasMS, id, isSSO, ssoFrom } = account;

  const icon = (
    <object
      type="image/svg+xml"
      data={microsoftIcon}
      className="x-icon"
      aria-label="Microsoft"
    />
  );

  return (
    <div className="microsoft">
      {hasMS ? (
        <Binding
          icon={icon}
          onClick={handleUnbind}
          canUnbind={!(ssoFrom === "Microsoft" && isSSO)}
        >
          {msEmail}
        </Binding>
      ) : bindUrl ? (
        <a id="microsoft-signin" href={bindUrl}>
          {icon}
          Sign in with Microsoft
        </a>
      ) : (
        <div id="microsoft-signin" onClick={handleBind}>
          {icon}
          Sign in and bind with Microsoft
        </div>
      )}
    </div>
  );
};

export default MicrosoftSSO;
