import React, { useContext, useEffect, useRef } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../Auth";
import LoginForm from "../components/LoginForm";
import ListItem from "../components/ListItem";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { platformList } from "../Data";

const isDev = process.env.REACT_APP_APP_ENV !== "production";

const useOnClickOutside = (
  ref: React.RefObject<HTMLElement>,
  handler: (event: MouseEvent | TouchEvent) => void
) => {
  useEffect(() => {
    const listener = (event: MouseEvent | TouchEvent) => {
      // Do nothing if clicking ref's element or descendent elements
      if (!ref.current || ref.current.contains(event.target as Node)) {
        return;
      }
      handler(event);
    };

    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);

    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, handler]);
};

export const DisplayShare = () => {
  const [isAuth, setAuth] = useContext(AuthContext);
  const navigate = useNavigate();

  // Create a ref that we add to the element for which we want to detect outside clicks
  const loginRef = useRef<HTMLDivElement>(null);
  const cookieAppend = useRef(false);

  // State for our modal
  const [isModalOpen, setModalOpen] = useState(false);

  // Call hook passing in the ref and a function to call on outside click
  useOnClickOutside(loginRef, () => setModalOpen(false));

  const handleLoginClicked = () => {
    if (isAuth) {
      console.log("Button clicked", isAuth);
      navigate("/settings");
    } else {
      setModalOpen(true);
    }
  };

  const onChangeAuth = (value: string) => {
    setAuth(value);
    // sessionStorage.setItem("accessToken", value);
    console.log(isAuth);
    navigate("/settings");
  };

  useEffect(() => {
    if (cookieAppend.current) return;
    cookieAppend.current = true;

    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src =
      "https://www.optoma.com/wp-content/themes/optoma-corporate-v2/assets/javascript/cookie.base.js?ver=1.9.15";
    document.body.appendChild(script);
  }, []);

  // useEffect(() => {
  // if (isAuth) navigate("/settings");
  // }, [isAuth, navigate]);

  console.log("CC", isAuth);

  return (
    <div className="app-container">
      <Header>
        <></>
      </Header>
      <div className="list-Container">
        {platformList.map((item, index) => (
          <ListItem
            key={index}
            icon={item.img}
            subject={item.subject}
            description={item.description}
            url={item.download}
          />
        ))}
      </div>
      <Footer />
      {isDev ? (
        isModalOpen &&
        !isAuth && (
          <div className="modal">
            <div className="modal-content" ref={loginRef}>
              <LoginForm onChange={onChangeAuth} />
            </div>
          </div>
        )
      ) : (
        <></>
      )}
    </div>
  );
};
