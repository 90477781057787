import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { MantineProvider } from "@mantine/core";
import AuthProvider from "./Auth";
import { DisplayShare } from "./routes/DisplayShare";
import { Passport } from "./routes/Passport";
import { Settings } from "./routes/Settings";
import "./App.css";

export const App = () => {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <DisplayShare />,
      errorElement: <DisplayShare />,
    },
    {
      path: "/displayshare",
      element: <DisplayShare />,
    },
    {
      path: "/passport",
      element: <Passport />,
    },
    {
      path: "/settings",
      element: <Settings />,
    },
  ]);

  return (
    <AuthProvider>
      <MantineProvider withGlobalStyles withNormalizeCSS>
        <RouterProvider router={router} />
      </MantineProvider>
    </AuthProvider>
  );
};
