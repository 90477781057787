import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  getPincodeAPI,
  googleLoginAPI,
  microsoftLoginAPI,
  verifyAPI,
} from "../Api";
import AppleSSO from "./AppleSSO";
import GoogleSSO from "./GoogleSSO";
import MicrosoftSSO from "./MicrosoftSSO";
import cx from "classnames";
import "./LoginForm.css";
import { AuthContext } from "../Auth";

interface LoginFormProps {
  onChange?: (token: string) => void;
}

enum Stage {
  REQUEST = "request",
  VERIFY = "verify",
}

const LoginForm: React.FC<LoginFormProps> = ({ onChange = () => {} }) => {
  const navigate = useNavigate();
  const [isAuth] = useContext(AuthContext);
  const [stage, setStage] = useState<Stage>(Stage.REQUEST);
  const [email, setEmail] = useState("");
  const [pincode, setPincode] = useState(""); // Reset pincode input when stage changes

  useEffect(() => {
    setEmail("");
    setPincode("");
  }, [stage]);

  const getPincode = async (event: React.FormEvent) => {
    event.preventDefault();
    const username = email.split("@")[0];
    const response = await getPincodeAPI({ username, email });
    if (response.status) setStage(Stage.VERIFY);
    else {
      console.log("error", response.message);
    }
  };

  const verify = async (event: React.FormEvent) => {
    event.preventDefault();
    const username = email.split("@")[0];
    const response = await verifyAPI({ email, passport: pincode }).catch(
      (error: any) => error
    ); // Passport expired

    if (!response.status) setStage(Stage.REQUEST);
    else {
      onChange(response.token);
    }
  };

  const submitText = stage === Stage.REQUEST ? "GetPincode" : "Login";
  const handleSubmit = stage === Stage.REQUEST ? getPincode : verify;

  return (
    <div className="login-form-container">
      <h2>Sign in</h2>{" "}
      <CCInputField
        type="text"
        placeholder="Email"
        value={email}
        onChange={setEmail}
      />
      <CCInputField
        className={stage === Stage.REQUEST ? "hidden" : ""}
        placeholder="PinCode"
        value={pincode}
        onChange={setPincode}
      />
      <CCSubmitButton text={submitText} onClick={handleSubmit} />
      <GoogleSSO
        account={{}}
        bindUrl={`${process.env.REACT_APP_API_URL}/api/user/google-login`}
        bind={async () => {
          const { status, url } = await googleLoginAPI();
          if (status) window.location.href = url;
        }}
      />
      <AppleSSO
        account={{}}
        bindUrl={`${process.env.REACT_APP_API_URL}/api/user/apple-login`}
      />
      <MicrosoftSSO
        account={{}}
        bind={async () => {
          const response = await microsoftLoginAPI(isAuth);
          if (response.status) {
            window.location.href = response.url;
          }
        }}
      />
    </div>
  );
};

interface CCInputFieldProps {
  className?: string;
  type?: string;
  placeholder: string;
  value: string;
  onChange: (value: string) => void;
}

const CCInputField: React.FC<CCInputFieldProps> = ({
  className,
  type,
  placeholder,
  value,
  onChange,
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };

  return (
    <input
      type={type}
      placeholder={placeholder}
      className={cx("input-field", className)}
      onChange={handleChange}
      value={value}
    />
  );
};

interface CCSubmitButtonProps {
  text: string;
  onClick: (event: any) => void;
}

const CCSubmitButton: React.FC<CCSubmitButtonProps> = React.memo(
  ({ text, onClick }) => {
    console.log("Button rendered");

    return (
      <div className="submit-container">
        <button className="submit-button" onClick={onClick}>
          {text}
        </button>
      </div>
    );
  }
);

export default LoginForm;
